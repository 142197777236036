(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice.newline')
    .controller('RefereeNewLine', RefereeNewLine);

  function  RefereeNewLine($mdToast, $scope, $state,  AclService, refereeLicense, $mdDialog, $filter, RefereeLicence, refId, InvoiceRef,Receptores) {
   var vm = this;
    vm.ctrlName = 'RefereeNewLine';
    
    vm.person=refereeLicense.person;
 vm.today=new Date();
 
  $scope.receptores=Receptores;
 $scope.sel=1;
 
  var linea={
       idref: refId,
      fecha:'',
      esport:'',
      numpartits: 0,
      preu:0,sinirpf:false,sintexto:false,idreceptor:1
            };
  
      vm.createLinea = function () {
      var r=vm.linea.preu.replace(",",".");
      vm.linea.esport=vm.linea.esport.substring(0,80);
      var sin='0';
      if (vm.linea.sinirpf){
          sin='1';
      }
       var sint='0';
      if (vm.linea.sintexto){
          sint='1';
      }
      
      linea = {
      idref: refId,
      fecha:parseDate(vm.linea.fecha),
      esport:vm.linea.esport,
      numpartits: vm.linea.numpartits,
      preu:r,
      sinirpf: sin,sintexto:sint,idreceptor:$scope.selectreceptor.idReceptor
      
        };
    
     InvoiceRef.save({Id: refId},linea);
      $mdToast.show(
        $mdToast.simple()
          .textContent($filter('translate')('Linia creada'))
          .position('bottom left')
          .hideDelay(6000)
      );   
      setTimeout( function() { $state.go('home.refereeNewInvoice', {licenseId: refId}, {reload: true, notify:true});},3000);
     
      };
      
   function parseDate(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  }
}());




